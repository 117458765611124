import {makeDependency} from '@/container';
import {GradeStatus} from '@/assignments/types/GradeStatus';
import Grade from '@/grades/models/Grade';

export const GetGradeStatus = makeDependency(() => {
  return getGradeStatus;
});

function getGradeStatus(grade: Grade | null) {
  if (!grade) {
    return GradeStatus.Ungraded;
  }
  if (grade.isOriginalCorrect()) {
    return GradeStatus.Correct;
  } else if (grade.isOriginalPartiallyCorrect()) {
    return GradeStatus.PartiallyCorrect;
  } else {
    return GradeStatus.Incorrect;
  }
}
