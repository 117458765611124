import {inject, makeDependency} from '@/container';
import {computed, ref, Ref, SetupContext, watch} from '@vue/composition-api';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import User from '@/users/models/User';
import {TaskGradeService} from '@/grades/services/TaskGradeService';
import {DependencyType} from '@/container/types/DependencyType';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import Grade from '@/grades/models/Grade';
import {useTranslate} from '@/locales/composables/i18n';
import {convertFractionComponentsToWholeNumbersAndReduce, precision} from '@/common/utils/math';

export enum ManualGradeEntryEvents {
  GradeSubmitted = 'GradeSubmitted',
}

export const UseManualGradeEntry = makeDependency(
  (
    emit: SetupContext['emit'],
    taskAssignment: Ref<TaskAssignment>,
    user: Ref<User>,
    originalGrade: Ref<Grade | null | undefined> = ref(null),
    weight: Ref<number> = ref(1),
    responseId: Ref<number | null>,
    oldFeedback: Ref<string | null> = ref(null),
    dialogOpenedState: Ref<boolean | null> = ref(null),
    maxDenominator: number = 1000,
    maxPrecision: number = 2
  ) =>
    useManualGradeEntry(
      inject(TaskGradeService),
      inject(LoadingFlagsService),
      useTranslate(),
      emit,
      taskAssignment,
      user,
      originalGrade,
      weight,
      responseId,
      oldFeedback,
      dialogOpenedState,
      maxDenominator,
      maxPrecision
    )
);

export const useManualGradeEntry = (
  taskGradeService: DependencyType<typeof TaskGradeService>,
  loadingFlagsService: DependencyType<typeof LoadingFlagsService>,
  $t: any,
  emit: SetupContext['emit'],
  taskAssignment: Ref<TaskAssignment>,
  user: Ref<User>,
  originalGrade: Ref<Grade | null | undefined>,
  weight: Ref<number>,
  responseId: Ref<number | null>,
  oldFeedback: Ref<string | null>,
  dialogOpenedState: Ref<boolean | null>,
  maxDenominator: number,
  maxPrecision: number
) => {
  const gradeNumerator: Ref<number> = ref(0);
  const gradeDenominator: Ref<number> = ref(taskAssignment.value.pointValue);
  const overrideReason: Ref<string | null> = ref(oldFeedback.value);
  const formValid: Ref<boolean> = ref(true);
  const forceRerenderForAutoGrow = ref<number>(0);

  resetForm();

  const numeratorRules = [
    () =>
      (gradeNumerator.value != null && String(gradeNumerator.value) != '') ||
      $t('formValidation.required'),
    () => gradeNumerator.value >= 0 || $t('manualGrades.greaterThanEqualZero'),
    () =>
      !gradeNumerator.value ||
      precision(gradeNumerator.value) <= maxPrecision ||
      $t('manualGrades.maxPrecision', {maxPrecision}),
  ];

  const lessThanEqualDenominatorRule = computed(() => [
    gradeNumerator.value <= gradeDenominator.value || $t('manualGrades.lessThanDenominator'),
  ]);

  const denominatorRules = [
    () =>
      gradeDenominator.value <= maxDenominator ||
      $t('manualGrades.maxDenominator', {maxDenominator}),
    () =>
      (gradeDenominator.value != null && String(gradeDenominator.value) != '') ||
      $t('formValidation.required'),
    () =>
      taskAssignment.value.pointValue === 0 ||
      gradeDenominator.value > 0 ||
      $t('manualGrades.greaterThanZero'),
    () =>
      !gradeDenominator.value ||
      precision(gradeDenominator.value) <= maxPrecision ||
      $t('manualGrades.maxPrecision', {maxPrecision}),
  ];

  function resetForm() {
    if (originalGrade.value) {
      gradeNumerator.value = +((originalGrade.value?.decimalGrade ?? 0) * weight.value).toFixed(2);
      gradeDenominator.value = weight.value;
    } else {
      gradeNumerator.value = 0;
      gradeDenominator.value = taskAssignment.value.pointValue;
    }
  }

  function submitGrade() {
    if (formValid.value) {
      return loadingFlagsService.loadingHandler(LoadingFlag.ManualGradeSubmit, () => {
        const gradeReducedFraction = convertFractionComponentsToWholeNumbersAndReduce({
          numerator: gradeNumerator.value,
          denominator: gradeDenominator.value || 1,
        });
        return taskGradeService
          .submitManualGrade(
            taskAssignment.value,
            user.value,
            gradeReducedFraction.numerator,
            gradeReducedFraction.denominator,
            overrideReason.value,
            responseId.value
          )
          .then((response) => {
            const newGrade: Grade = response.Grade[0] as Grade;
            if (newGrade.isEffective) {
              taskAssignment.value.grades
                .filter((grade) => {
                  return grade.id != newGrade.id;
                })
                .forEach((grade) => {
                  grade.makeIneffective();
                });
            }
            emit(ManualGradeEntryEvents.GradeSubmitted);
            resetForm();
            return response;
          });
      });
    }
  }

  const submitManualGradeLoading = computed(() =>
    loadingFlagsService.isLoading(LoadingFlag.ManualGradeSubmit)
  );

  watch(originalGrade, () => {
    resetForm();
  });

  watch(oldFeedback, (newVal, oldValue) => {
    overrideReason.value = newVal;
  });

  // This is a hack to get the auto grow of the grade override feedback textarea working.
  watch(dialogOpenedState, (newVal, oldValue) => {
    forceRerenderForAutoGrow.value += 1;
  });

  return {
    gradeNumerator,
    gradeDenominator,
    overrideReason,
    submitManualGradeLoading,
    numeratorRules,
    denominatorRules,
    formValid,
    lessThanEqualDenominatorRule,
    submitGrade,
    responseId,
    forceRerenderForAutoGrow,
  };
};
