

































































import {defineComponent, PropType, toRef} from '@vue/composition-api';
import User from '@/users/models/User';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import {inject} from '@/container';
import {
  ManualGradeEntryEvents,
  UseManualGradeEntry,
} from '@/grades/composables/UseManualGradeEntry';
import Grade from '@/grades/models/Grade';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  components: {STextarea},
  props: {
    taskAssignment: {
      type: TaskAssignment as PropType<TaskAssignment>,
      required: true,
    },
    user: {
      type: User as PropType<User>,
      required: true,
    },
    originalGrade: {
      type: Grade as PropType<Grade>,
      required: false,
    },
    weight: {
      type: Number as PropType<number>,
      required: false,
      default: 1,
    },
    oldFeedback: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    responseId: {
      type: Number as PropType<number>,
      required: false,
      default: null,
    },
    dialogOpenedState: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: null,
    },
  },
  emits: Object.values(ManualGradeEntryEvents),
  setup(props, {emit}) {
    const {
      gradeNumerator,
      gradeDenominator,
      overrideReason,
      submitGrade,
      submitManualGradeLoading,
      numeratorRules,
      denominatorRules,
      lessThanEqualDenominatorRule,
      formValid,
      responseId,
      forceRerenderForAutoGrow,
    } = inject(
      UseManualGradeEntry,
      emit,
      toRef(props, 'taskAssignment'),
      toRef(props, 'user'),
      toRef(props, 'originalGrade'),
      toRef(props, 'weight'),
      toRef(props, 'responseId'),
      toRef(props, 'oldFeedback'),
      toRef(props, 'dialogOpenedState')
    );

    return {
      gradeNumerator,
      gradeDenominator,
      overrideReason,
      submitManualGradeLoading,
      submitGrade,
      numeratorRules,
      denominatorRules,
      lessThanEqualDenominatorRule,
      formValid,
      responseId,
      forceRerenderForAutoGrow,
    };
  },
});
