<template>
  <div class="task-view-stack">
    <div>
      <task-card
        v-if="task"
        ref="taskCard"
        :key="task.id"
        :task="task"
        :task-assignment="taskAssignment"
        :assignment="assignment"
        :responses="responses"
        :response="response"
        :task-state="taskState"
        :feedback-by-part="feedbackByPart"
        :grade="grade"
        :effective-grade="effectiveGrade"
        :index="taskIndex"
        :selected-response-index="responseIndex"
        :user="user"
        :try-it="tryIt"
        :loading="loading"
        :response-attachments="responseAttachments"
        :auto-save-enabled="autoSaveEnabled"
        @change-selected-response="changeSelectedResponse"
        @select-latest-response="selectLatestResponse"
        @clear-attachments="clearAttachments"
        @add-try-it-response="$emit('add-try-it-response', $event)"
        @reload-task="$emit('reload-task', $event)"
        @response-submitted="
          isFormDirty = false;
          $emit('response-submitted', $event);
        "
        @response-saved="isFormDirty = false"
        @update:inputData="isFormDirty = true"
      />
    </div>
    <div class="action-footer">
      <div class="footer-meta">
        <task-buttons class="d-inline-block" :task="task" />
        <v-btn
          fab
          small
          color="secondary"
          rounded
          height="30px"
          width="30px"
          class="periodic-table-button button-no-margin"
          @click.stop="openPeriodicTable"
        >
          <v-icon>mdi-periodic-table</v-icon>
        </v-btn>
        <popup-periodic-table :visible="showPeriodicTable" @close="showPeriodicTable = false" />
        <span v-if="reactiveSubmittedTime" class="attempt">
          <span class="attempt-label"
            >{{ $t('attempt') }} <span class="attempt-number"> {{ attemptNumber }}</span>
            <span class="attempt-dash">&ndash;</span></span
          >
          <span class="attempt-time">{{ reactiveSubmittedTime }}</span>
        </span>
        <v-tooltip v-if="response && response.isDraft" top :disabled="isSavingDraft">
          <template v-slot:activator="{on}">
            <div v-if="!isSavingDraft" class="draft-badge" v-on="on">
              <v-icon>mdi-check</v-icon>
              Draft Saved
            </div>
            <div v-else class="draft-badge --saving" v-on="on">
              <v-icon>mdi-loading</v-icon>
              Saving Draft
            </div>
          </template>
          <span>
            {{
              $t('assignmentPage.savedAtTooltip', {
                savedAt: formatDate(response.lastSavedAt || response.createdAt),
              })
            }}
          </span>
        </v-tooltip>
      </div>
      <div class="footer-actions">
        <response-dots
          :responses="responses"
          :response-index="responseIndex"
          @change-selected-response="changeSelectedResponse"
        />
        <v-btn
          v-if="enableSelect"
          color="secondary"
          rounded
          width="120px"
          height="30px"
          class="mr-2"
          @click="emitTaskSelect"
          >{{ $t('select-task') }}
        </v-btn>
        <div id="submitButtonWrapper" class="action-wrapper">
          <v-btn
            v-if="allowRegrade && canSubmitGrades"
            class="submit-response-button button-no-margin"
            color="secondary"
            rounded
            :loading="isRegrading"
            :disabled="isRegrading || isRegradeDisabled"
            data-test="regrade-response"
            @click="regradeResponse"
          >
            {{ $t('Regrade') }}
          </v-btn>

          <v-tooltip top :disabled="isSubmitting || !isSubmitDisabled">
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-btn
                  class="submit-response-button button-no-margin"
                  color="secondary"
                  rounded
                  :disabled="isSubmitting || isSubmitDisabled"
                  :loading="isSubmitting"
                  data-test="save-response"
                  @click="triggerSubmitResponse"
                >
                  {{ $t('submit-response') }}
                </v-btn>
              </div>
            </template>
            <span v-if="submitTooltipText">{{ submitTooltipText }}</span>
          </v-tooltip>
          <v-dialog v-if="isMarking" v-model="gradeEditDialog" max-width="600" min-height="400">
            <template v-slot:activator="{attrs, on}">
              <v-btn
                color="secondary"
                rounded
                class="button-no-margin"
                v-bind="attrs"
                data-test="edit-grade-button"
                v-on="on"
              >
                <v-icon left>{{ $i('common.edit') }}</v-icon>
                {{ $t('manualGrades.editGradeLabel') }}
              </v-btn>
            </template>
            <v-toolbar flat dark color="primary">
              <v-btn icon dark @click="gradeEditDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ $t('manualGrades.dialogTitle') }}</v-toolbar-title>
            </v-toolbar>
            <manual-grade-entry
              :task-assignment="taskAssignment"
              :user="user"
              :original-grade="effectiveResponseGrade"
              :weight="taskAssignment ? taskAssignment.pointValue : 1"
              :old-feedback="existingFeedback"
              :response-id="response && response.id"
              :dialog-opened-state="gradeEditDialog"
              v-on="$listeners"
              @[manualGradeEntrySubmitted]="gradeEditDialog = false"
            ></manual-grade-entry>
          </v-dialog>
        </div>
      </div>
    </div>
    <s-attachments
      v-if="response && response.attachments && response.attachments.length > 0"
      :allow-new-uploads="canViewGradesFor"
      :attachments="fileUploads"
      :entity-id="response ? response.id : null"
      entity-type="TaskResponse"
      @[attachmentSubmitEvent]="attachmentsAdded"
      @[attachmentDeletedEvent]="attachmentsDeleted($event)"
    ></s-attachments>
    <div v-if="feedbackByPart || response || effectiveResponseGrade">
      <feedback-area
        v-if="!response || !response.isDraft"
        ref="feedbackArea"
        :task-weight="taskAssignment ? taskAssignment.pointValue : 1"
        :feedback-by-part="feedbackByPart"
        :grade="effectiveResponseGrade"
        :response="response"
      />
    </div>
    <div class="new-row">
      <stemble-alert
        v-model="alertBook"
        icon="mdi-book-open-page-variant"
        color="#C5E1A5"
        class="black--text mx-2"
        width="100%"
      >
        <v-row align="center">
          <v-col class="grow">
            <b>BOOK TITLE</b>
          </v-col>
          <v-col class="shrink">
            <v-btn :href="'book link'">
              {{ $t('more-info') }}
            </v-btn>
          </v-col>
        </v-row>
      </stemble-alert>
    </div>
  </div>
</template>

<script>
import TaskState from '../../task-states/models/TaskState';
import TaskCard from '@/tasks/components/TaskCard';
import FeedbackArea from '@/tasks/components/FeedbackArea';
import Task from '@/tasks/models/Task';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import Assignment from '@/assignments/models/Assignment';
import TaskButtons from '@/tasks/components/TaskButtons';
import TaskResponse from '@/tasks/models/TaskResponse';
import Grade from '@/grades/models/Grade';

import User from '@/users/models/User';
import moment from 'moment';
import {format} from 'date-fns';
import ResponseDots from '@/tasks/components/ResponseDots';
import StembleAlert from '@/common/components/StembleAlert.vue';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import SAttachments, {events as AttachmentEvents} from '@/uploads/components/SAttachments';
import FileSelectDialog from '@/uploads/components/FileSelectDialog';
import ResponseFileUpload from '@/tasks/models/ResponseFileUpload';
import ManualGradeEntry from '@/grades/views/ManualGradeEntry';
import {ManualGradeEntryEvents} from '@/grades/composables/UseManualGradeEntry';
import {FRIENDLY_DATE_AND_FORMAT} from '@/datetime/utils/datetime';
import PopupPeriodicTable from '@/tasks/components/periodic-table/PopupPeriodicTable.vue';
import useAnalytics from '@/telemetry/composables/use-analytics';
import {useOrderedFeedbackParts} from '@/tasks/composables/useOrderedFeedbackParts';
import {computed, toRef} from '@vue/composition-api';
import {ErrorCode} from '@/common/api/ErrorCode';

export default {
  name: 'TryItArea',
  components: {
    PopupPeriodicTable,
    ManualGradeEntry,
    SAttachments,
    FileSelectDialog,
    StembleAlert,
    ResponseDots,
    TaskButtons,
    FeedbackArea,
    TaskCard,
  },
  props: {
    task: {
      type: Task,
      required: true,
    },
    taskAssignment: {
      type: TaskAssignment,
      required: false,
      default: null,
    },
    assignment: {
      type: Assignment,
      required: false,
      default: null,
    },
    responses: {
      type: Array,
      required: true,
    },
    response: {
      type: TaskResponse,
      required: false,
      default: null,
    },
    taskState: {
      type: TaskState,
      required: false,
      default: null,
    },
    feedbackByPart: {
      type: Object,
      required: false,
      default: null,
    },
    grade: {
      type: Grade,
      required: false,
      default: null,
    },
    effectiveGrade: {
      type: Grade,
      required: false,
      default: null,
    },
    taskIndex: {
      type: Number,
      required: true,
    },
    responseIndex: {
      type: Number,
      required: true,
    },
    progressBarValue: {
      type: Number,
      required: false,
      default: null,
    },
    user: {
      type: User,
      required: true,
    },
    tryIt: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableSubmit: {
      type: Boolean,
      required: false,
    },
    isMarking: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowRegrade: {
      type: Boolean,
      default: false,
    },
    assignmentExtension: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const feedbackTextParts = computed(() => {
      const orderedFeedbackParts = useOrderedFeedbackParts(
        toRef(props, 'feedbackByPart'),
        toRef(props, 'grade'),
        toRef(props, 'response')
      );
      return orderedFeedbackParts.feedbackTextByPart;
    });
    const feedbackPartNames = computed(() => {
      const orderedFeedbackParts = useOrderedFeedbackParts(
        toRef(props, 'feedbackByPart'),
        toRef(props, 'grade'),
        toRef(props, 'response')
      );
      return orderedFeedbackParts.feedbackPartNames;
    });
    return {
      feedbackTextParts,
      feedbackPartNames,
    };
  },
  data: () => ({
    alertVideo: false,
    alertBook: false,
    timestampInterval: null,
    reactiveSubmittedTime: '',
    responseUploadDialog: false,
    responseAttachments: [],
    attachmentSubmitEvent: AttachmentEvents.ATTACHMENT_SUBMIT_EVENT,
    attachmentDeletedEvent: AttachmentEvents.ATTACHMENT_DELETED_EVENT,
    fileSelectDialog: false,
    gradeEditDialog: false,
    manualGradeEntrySubmitted: ManualGradeEntryEvents.GradeSubmitted,
    showPeriodicTable: false,
    isRegradeDisabled: false,
    autoSaveEnabled: false, // This is hard-coded off now that Inertia re-write is going out
    isFormDirty: false,
  }),
  computed: {
    effectiveResponseGrade() {
      const effectiveResponseGradeForUser = this.taskAssignment?.effectiveResponseGradeForUser(
        this.response?.id,
        this.user.id
      );
      return effectiveResponseGradeForUser ?? this.grade;
    },
    isRegrading() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentRegradingResponse);
    },
    isSavingDraft() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentStoringResponse);
    },
    isSubmitting() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentSubmittingResponse);
    },
    isSubmitDisabled() {
      return (
        this.disableSubmit ||
        // Cannot submit an already submitted response
        (this.autoSaveEnabled && this.response && this.response.submittedAt && !this.isFormDirty) ||
        this.preventSubmissionBasedOnDueDate ||
        this.task.taskType === 'WatchVideoTask' ||
        (this.task.taskType === 'DynamicQuestion' && !this.task.content.hasGrader)
      );
    },
    preventSubmissionBasedOnDueDate() {
      const now = moment();

      if (!this.assignment?.courseLikeAssignments?.length) {
        return false;
      }

      // If the preventPastDueSubmission flag is not enabled, we don't need to prevent submission
      if (!this.assignment.courseLikeAssignments[0].preventPastDueSubmission) {
        return false;
      }

      // If the student has an extension, have they passed the extension due date?
      if (this.assignmentExtension && this.assignmentExtension.newDueDate) {
        return now.isAfter(this.assignmentExtension.newDueDate);
      }

      // Otherwise, have they passed the assignment due date?
      return now.isAfter(this.assignment.courseLikeAssignments[0].dueDate);
    },
    submitTooltipText() {
      if (this.disableSubmit) {
        return null;
      } else if (this.response && this.response.submittedAt) {
        return this.$t('assignmentPage.alreadySubmitted');
      } else if (this.preventSubmissionBasedOnDueDate) {
        return 'Sorry, you cannot submit a response. The assignment due date has already passed.';
      } else if (this.task.taskType === 'WatchVideoTask') {
        return this.$t('assignmentPage.video-will-auto-submit');
      } else if (this.task.taskType === 'DynamicQuestion') {
        return this.$t('assignmentPage.notGradedThroughStemble');
      }
      return null;
    },
    attemptNumber() {
      return this.responseIndex + 1;
    },
    fileUploads() {
      return this.response ? this.response.attachments : [];
    },
    canViewGradesFor() {
      return this.assignment && this.$auth.user.can('viewGradesFor', 'Assignment', this.assignment);
    },
    canSubmitGrades() {
      return (
        this.assignment && this.$auth.user.can('submitGradesFor', 'Assignment', this.assignment)
      );
    },
    existingFeedback() {
      return this.effectiveResponseGrade?.overrideReason || this.formatFeedback() || '';
    },
    isFaculty() {
      return this.$auth.user.isFaculty();
    },
  },
  watch: {
    responses() {
      this.selectLatestResponse();
    },
    response() {
      this.updateLastSaveResponseTime();
    },
  },
  created() {
    this.createResponseTimestampInterval();
  },
  destroyed() {
    this.clearTimestampInterval();
  },
  methods: {
    formatFeedback() {
      let feedbackString = '';
      for (const feedback in this.feedbackTextParts.value) {
        if (feedback === 'default') {
          feedbackString = `General\n${this.feedbackTextParts.value[feedback]}\n\n${feedbackString}`;
        } else {
          feedbackString += `${feedback}\n${this.feedbackTextParts.value[feedback]}\n\n`;
        }
      }
      return feedbackString;
    },
    createResponseTimestampInterval() {
      this.timestampInterval = setInterval(() => {
        this.updateLastSaveResponseTime();
      }, 60 * 1000);
    },
    clearTimestampInterval() {
      if (this.timestampInterval !== null) {
        clearInterval(this.timestampInterval);
        this.timestampInterval = null;
      }
    },
    updateLastSaveResponseTime() {
      if (this.response && this.response.submittedAt) {
        const responseTime = moment(this.getResponseTimestamp(this.response));
        this.reactiveSubmittedTime = responseTime.format(FRIENDLY_DATE_AND_FORMAT);
        return;
      }
      this.reactiveSubmittedTime = '';
    },
    changeSelectedResponse(newIndex) {
      this.$emit('change-selected-response', newIndex);
    },
    clearAttachments() {
      this.responseAttachments = [];
    },
    selectLatestResponse() {
      this.changeSelectedResponse(this.responses.length ? this.responses.length - 1 : 0);
    },
    // Response submission
    triggerSubmitResponse() {
      this.updateLastSaveResponseTime();
      this.$refs.taskCard.triggerSubmitResponse();
    },
    triggerStoreResponse() {
      this.updateLastSaveResponseTime();
      this.$refs.taskCard.storeResponse();
    },
    regradeResponse() {
      this.$loadingFlags
        .loadingHandler(
          LoadingFlag.AssignmentRegradingResponse,
          TaskResponse.api.regradeResponse(this.response.id)
        )
        .then(async () => {
          this.$emit('clear-attachments');
          return Grade.api
            .loadGradesForTask(this.user.id, this.assignment.id, this.task.id)
            .catch((err) => {
              if (err.response && err.response.status === 403) {
                // Student likely just not allowed to see their grade yet
                return;
              }
              return this.$errorReporting.showErrorDialog(err, {
                text: 'There was an error fetching your grade. Try refreshing the page. If that does not work, please let us know.',
              });
            });
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            // TODO: this should be more informative based on policies or maybe error codes
            return this.$errorReporting.showErrorDialog(err, {
              text: 'You are not authorized to regrade responses.',
              options: {
                enableSendingLogs: false,
                showEmailSupport: false,
                buttonText: 'Close',
              },
            });
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.code === ErrorCode.INVALID_CHEMICAL_EQUATION
          ) {
            return this.$errorReporting.showErrorDialog(err, {
              text: 'One or more chemical equations are invalid. Check the equation(s) highlighted in red.',
              options: {
                enableSendingLogs: false,
                showEmailSupport: false,
                buttonText: 'Close',
              },
            });
          } else {
            return this.$errorReporting.showErrorDialog(err, {
              text: 'There was an error submitting your response. Try again soon, but let us know if the issue persists.',
            });
          }
        })
        .then(() => {
          this.selectLatestResponse();
          this.isRegradeDisabled = true;
          this.$successSnackbar.open({
            messageTranslationKey: 'successSnackbar.regradingInProgress',
            showButton: true,
          });
        });
    },
    taskCardUpdate() {
      this.$refs.taskCard.forceUpdate();
    },
    emitTaskSelect() {
      this.$emit('task-select', this.task.id);
      this.$emit('close');
    },
    resetForNewQuestion: function () {
      this.alertBook = false;
      this.alertVideo = false;
      this.responsesAlter = false;
    },
    reloadTask() {
      this.$emit('reload-task');
    },
    attachmentsAdded() {
      this.$emit('attachments-updated');
    },
    attachmentsDeleted(deletedAttachments) {
      deletedAttachments.forEach((id) => ResponseFileUpload.delete(id));
    },
    openPeriodicTable() {
      this.showPeriodicTable = true;
      const analytics = useAnalytics();
      analytics.track('Periodic Table: opened');
    },
    formatDate(date) {
      return format(new Date(date), 'MMMM do, yyyy, h:mm aa');
    },
    getResponseTimestamp(response) {
      if (!response) {
        return '';
      }

      // For responses submitted by the user themselves, we want the submitted at date
      if (response.isSelfSubmitted && response.submittedAt) {
        return response.submittedAt;
      }

      // When a response is not yet submitted or was submitted on someone's behalf, we want the last time the
      // response's owning user stored the response so instructors can better determine when the work was completed
      return response.lastSavedAt || response.createdAt;
    },
  },
};
</script>

<style>
.draft-badge {
  font-weight: medium;
  background: linear-gradient(to right, #e4f7ea, #d3f3de);
  border: 1.5px solid #beedcd;
  color: #549a62;
  box-shadow:
    inset 0.5px 2px 6px 0px rgba(44, 32, 80, 0.04),
    inset 0px 1px 2px 0px rgba(46, 36, 81, 0.03),
    0px 1.5px 3px -1px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem 0.5rem 0.875rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  transition: all ease-out 150ms;
}

.draft-badge .v-icon {
  color: #69b979;
}

.draft-badge.--saving {
  background: #ededed;
  border-color: #e4e4e4;
  color: #838383;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.draft-badge.--saving .v-icon {
  color: #2c7cbe;
  animation: spin 1s linear infinite;
}

.task-view-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2rem;
}

.action-footer {
  width: 100%;
  display: flex;
  margin-top: -0.75rem;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.footer-meta {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer-actions {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
}

.action-wrapper {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem 0.5rem;
  flex-wrap: wrap;
}

.submit-response-button {
  width: 140px;
  font-size: 0.875rem !important;
  font-weight: bold;
}

.button-no-margin {
  margin: 0 !important;
}

.attempt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0.25rem;
  padding: 0.375rem 1rem;
  border-radius: 1.25rem;
  box-shadow:
    0px 1px 2px 0px rgba(18, 11, 40, 0.03),
    0px 4px 10px 0px rgba(46, 36, 81, 0.05);
  background: #fff;
  border: 1px solid #dedede;
  color: #3875a7;
  font-size: 1.125rem;
  line-height: 1.2;
}

.attempt-dash {
  margin: 0 0.25rem;
  opacity: 0.5;
}

.attempt-label {
  font-weight: bold;
  white-space: nowrap;
}

.muted-text {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 12px;
}

.submitButton {
  background-color: #d83d0e !important;
  border: 0 !important;
}

.theme--light.v-label {
  color: black !important;
}

.is-selected {
  background-color: #b9c9d8 !important;
  color: white !important;
}

.is-active-reload {
  color: #ffac6e !important;
}

.text-btn::before {
  color: transparent !important;
}

.text-btn:hover {
  color: #d83d0e !important;
}

.text-btn {
  margin-right: -18px;
}

.no-background-hover::before {
  background-clor: transparent !important;
}

.v-card__text {
  line-height: 28px;
}

.progressContainer {
  width: 60%;
  margin: 0 auto;
}

.progress {
  margin: 0;
  border-radius: 5rem;
  overflow: hidden;
  border: none;
  background-color: red !important;
}

@media only screen and (min-width: 768px) {
  .quiz_card_height {
    height: 400px;
  }

  .quiz_button_width {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .quiz_card_height {
    height: 100%;
  }

  .quiz_button_width {
    width: 100%;
  }
}

.try-it-pointer-events {
  pointer-events: auto;
}
</style>
