











































import {defineComponent, PropType, ref, watch} from '@vue/composition-api';
import {useTranslate} from '@/locales/composables/i18n';
import {Color} from '@/theme/types';

export const events = {
  CONFIRM_DIALOG_CONFIRM: 'confirm-dialog-confirm',
  CONFIRM_DIALOG_CANCEL: 'confirm-dialog-cancel',
};

const $t = useTranslate();
type DialogCallback = () => void;
type DialogButton = {text: string; color: Color};
export default defineComponent({
  emits: Object.values(events),
  model: {prop: 'modelValue', event: 'update:modelValue'},
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    confirmAction: {
      type: Function as PropType<DialogCallback>,
      default: () => async () => {},
    },
    cancelAction: {
      type: Function as PropType<DialogCallback>,
      default: () => async () => {},
    },
    title: {
      type: String as PropType<string>,
      default: $t('confirmDialog.defaultTitle'),
    },
    content: {
      type: String as PropType<string>,
      default: $t('confirmDialog.defaultContent'),
    },
    autoclose: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    confirmButtonOptions: {
      type: Object as PropType<DialogButton>,
      default: () => ({text: $t('confirmDialog.confirm'), color: 'primary'}),
    },
    cancelButtonOptions: {
      type: Object as PropType<DialogButton>,
      default: () => ({text: $t('confirmDialog.cancel'), color: 'grey'}),
    },
  },
  setup(props, {emit}) {
    const dialogOpen = ref(props.modelValue);
    const isLoading = ref(false);
    watch(
      () => props.modelValue,
      (newValue: boolean) => (dialogOpen.value = newValue)
    );

    function updateModelValue(newValue: boolean = false) {
      emit('update:modelValue', newValue);
      if (props.autoclose) {
        dialogOpen.value = newValue;
      }
    }

    async function runCallback(cb: DialogCallback, event: string) {
      await cb();
      emit(event);
      updateModelValue();
    }

    function _confirm() {
      isLoading.value = true;
      runCallback(props.confirmAction, events.CONFIRM_DIALOG_CONFIRM).then(
        () => (isLoading.value = false)
      );
    }

    function _cancel() {
      runCallback(props.cancelAction, events.CONFIRM_DIALOG_CANCEL);
    }

    return {
      dialogOpen,
      isLoading,
      updateModelValue,
      _confirm,
      _cancel,
    };
  },
});
