


































import {computed, defineComponent, PropType} from '@vue/composition-api';
import TaskResponse from '@/tasks/models/TaskResponse';
import {inject} from '@/container';
import {UseResponseDots} from '@/tasks/composables/UseResponseDots';

export default defineComponent({
  name: 'ResponseDots',
  props: {
    responses: {
      // FIXME: add TaskResponse type when ready
      type: Array as PropType<TaskResponse[]>,
      required: true,
      default: [],
    },
    responseIndex: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const {getResponseInformation, changeSelectedResponse} = inject(UseResponseDots, emit);

    const allResponseData = computed(() =>
      props.responses.map((response) => getResponseInformation(response, null))
    );

    return {
      allResponseData,
      changeSelectedResponse,
    };
  },
});
