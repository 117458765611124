<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn color="primary" class="ma-0" flat right @click.stop="closePopUp">Close</v-btn>
        </v-col>
      </v-card-actions>
      <periodic-table></periodic-table>
    </v-card>
  </v-dialog>
</template>

<script>
import PeriodicTable from '@/tasks/components/periodic-table/PeriodicTable.vue';
import analytics from 'analytics';
import useAnalytics from '@/telemetry/composables/use-analytics';

export default {
  name: 'PopupPeriodicTable',
  components: {PeriodicTable},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    closePopUp() {
      this.show = false;
      const analytics = useAnalytics();
      analytics.track('Periodic Table: closed');
    },
  },
};
</script>

<style scoped></style>
