import {inject, makeDependency} from '@/container';
import {computed, Ref, ref} from '@vue/composition-api';
import {FileUploadService} from '@/uploads/services/FileUploadService';
import FileUpload from '@/tasks/models/FileUpload';
import {DependencyType} from '@/container/types/DependencyType';
import {downloadBlob} from '@/common/utils/downloadBlob';
import {FileUploadLinkedEntity} from '@/tasks/types/FileUploadLinkedEntity';

export const UseAttachments = makeDependency(
  (entityType: Ref<FileUploadLinkedEntity>, entityId: Ref<number>) =>
    useAttachments(inject(FileUploadService), entityType, entityId)
);

export const useAttachments = (
  fileUploadService: DependencyType<typeof FileUploadService>,
  entityType: Ref<FileUploadLinkedEntity>,
  entityId: Ref<number>
) => {
  const fileSelectDialog = ref(false);
  const newFileUpload = ref<any>();

  const headers = [
    {
      text: 'Filename',
      value: 'filename',
    },
    {
      text: 'Owner',
      value: 'owner',
    },
    {
      text: 'Created Date',
      value: 'createdAtFormatted',
    },
    {
      text: 'Part',
      value: 'part',
    },
    {
      value: 'actions',
      sortable: false,
    },
  ];

  const footerProps = {
    itemsPerPageOptions: [5],
    disableItemsPerPage: true,
  };

  const uploadFileSelected = computed((): boolean => {
    return !!newFileUpload.value;
  });

  const uploadNewFileAttachment = async () => {
    const fileUpload = new FileUpload();
    fileUpload.linkedEntityType = entityType.value;
    fileUpload.filename = newFileUpload.value?.name;
    fileUpload.linkedEntityId = entityId.value;
    if (newFileUpload.value) {
      return fileUploadService
        .createFileUpload(newFileUpload.value, fileUpload)
        .then((response) => {
          newFileUpload.value = [];
          return response;
        });
    }

    throw new Error('Cannot upload without a file');
  };

  function downloadFileUpload(fileUpload: FileUpload, openInNewTab: boolean) {
    fileUploadService
      .downloadFileUpload(fileUpload)
      .then((blob) => {
        downloadBlob(blob, fileUpload.filename, openInNewTab);
      })
      .catch(console.error);
  }

  function deleteFileUpload(fileUpload: FileUpload) {
    return fileUploadService.deleteFileUpload(fileUpload);
  }

  return {
    headers,
    footerProps,
    newFileUpload,
    uploadFileSelected,
    fileSelectDialog,
    downloadFileUpload,
    uploadNewFileAttachment,
    deleteFileUpload,
  };
};
