
























































import StembleLatex from '@/tasks/components/StembleLatex.vue';
import Grade from '@/grades/models/Grade';
import StembleAlert from '@/common/components/StembleAlert.vue';
import {inject} from '@/container';
import TaskGrade from '@/tasks/components/TaskGrade.vue';
import TaskResponseTaskFeedback, {DEFAULT_PART_NAME} from '@/tasks/models/TaskResponseTaskFeedback';
import {computed, defineComponent, PropType, toRef} from '@vue/composition-api';
import {getGradeColor} from '@/grades/utils/grade-color';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {UseAdjustmentFeedbackPostScript} from '@/tasks/composables/UseAdjustmentFeedbackPostScript';
import {useTranslate} from '@/locales/composables/i18n';
import {useOrderedFeedbackParts} from '@/tasks/composables/useOrderedFeedbackParts';
import TaskResponse from '@/tasks/models/TaskResponse';
import DOMPurify from 'dompurify';

export default defineComponent({
  name: 'FeedbackArea',
  components: {TaskGrade, StembleAlert, StembleLatex},
  props: {
    feedbackByPart: {
      type: Object as PropType<Record<string, TaskResponseTaskFeedback[]> | null>,
      required: false,
      default: null,
    },
    taskWeight: {
      type: Number as PropType<number>,
      default: 1,
    },
    grade: {
      type: Grade as PropType<Grade | null>,
      required: false,
      default: null,
    },
    response: {
      type: TaskResponse as PropType<TaskResponse | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const $t = useTranslate();
    const adjustments = computed(() =>
      // FIXME: Filtering to avoid weird vuex-orm bug
      GradeAdjustment.fullQuery({})
        .where('gradeId', props.grade?.id)
        .all()
        .filter((ga) => ga.$id !== 'undefined')
    );

    const {adjustmentsApplied, adjustmentReason} = inject(
      UseAdjustmentFeedbackPostScript,
      adjustments,
      toRef(props, 'taskWeight')
    );

    const gradeColor = computed(() => getGradeColor(props.grade));

    function processText(content: string) {
      // TODO: When we rebuild this in Vue 3/inertia, let's try not to rawly render HTML but instead parse the lines of feedback
      // and loop over them to build the HTML, rendered through {{ line }}. This would be more secure.
      return DOMPurify.sanitize(content.replace(/(?:\r\n|\r|\n)/g, '<br>'));
    }

    const overrideReason = computed(() => props.grade?.overrideReason);
    const {
      feedbackTextByPart,
      feedbackPartNames,
      isOnlyDefaultFeedback,
      isSingleFeedback,
      genericFeedbackText,
    } = useOrderedFeedbackParts(
      toRef(props, 'feedbackByPart'),
      toRef(props, 'grade'),
      toRef(props, 'response')
    );

    function getPartTitle(part: string) {
      return part === DEFAULT_PART_NAME ? 'General' : part;
    }

    return {
      adjustmentsApplied,
      adjustmentReason,
      gradeColor,
      feedbackTextByPart,
      feedbackPartNames,
      isSingleFeedback,
      isOnlyDefaultFeedback,
      genericFeedbackText,
      getPartTitle,
      processText,
      overrideReason,
    };
  },
});
