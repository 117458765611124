export function downloadBlob(blob: Blob, filename?: string | null, openInNewTab: boolean = false) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);

  if (openInNewTab) {
    link.target = '_blank';
  } else if (filename) {
    link.download = filename ?? link.href.substring(link.href.lastIndexOf('/'));
  }

  link.click();
  URL.revokeObjectURL(link.href);
}
