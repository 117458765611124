import {inject, makeGlobalSingleton} from '@/container';
import {
  LoadingFlagsService,
  LoadingFlagsServiceClass,
} from '@/loading/services/LoadingFlagsService';
import FileUpload from '@/tasks/models/FileUpload';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {IStembleAxiosResponse} from '@/common/api/types/IStembleAxiosResponse';
import {AxiosResponse} from 'axios';

export const FileUploadService = makeGlobalSingleton(() =>
  makeFileUploadService(inject(LoadingFlagsService))
);

function makeFileUploadService($loadingFlags: LoadingFlagsServiceClass) {
  function downloadFileUpload(fileUpload: FileUpload) {
    return $loadingFlags.loadingHandler(
      LoadingFlag.FileUploadDownload,
      FileUpload.api.download(fileUpload.id).then((response: AxiosResponse<BlobPart>) => {
        return new Blob([response.data], {
          type: response.headers[`content-type`],
        });
      })
    );
  }

  function createFileUpload(files: File[], fileUpload: FileUpload) {
    return $loadingFlags.loadingHandler(
      LoadingFlag.FileUploadUpload,
      FileUpload.api.createMany(files, fileUpload)
    );
  }

  function deleteFileUpload(fileUpload: FileUpload) {
    return $loadingFlags.loadingHandler(
      LoadingFlag.FileUploadDelete,
      FileUpload.api.remove(fileUpload)
    );
  }

  return {
    downloadFileUpload,
    createFileUpload,
    deleteFileUpload,
  };
}
