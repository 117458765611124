import {makeDependency} from '@/container';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {PolicyType} from '@/assignment-policies/types/RawAssignmentPolicy';
import {usePluralizedTranslations} from '@/locales/composables/i18n';
import {GradeAdjustmentType} from '@/grades/types/RawGradeAdjustment';

export const UseBuildAdjustmentInfo = makeDependency(() => {
  const $tc = usePluralizedTranslations();
  return (adjustment: GradeAdjustment) => buildAdjustmentInfo(adjustment, $tc);
});

export function buildAdjustmentInfo(adjustment: GradeAdjustment, $tc: (...args: any[]) => string) {
  const prepender = adjustment.value > 0 ? '+' : '-';
  const value = Math.abs(adjustment.value);
  const appender =
    adjustment.adjustmentType === GradeAdjustmentType.Relative
      ? '%'
      : ' ' + $tc('gradeAdjustments.points', Math.abs(adjustment.value) === 1 ? 0 : 1);

  const reason =
    adjustment.policy?.type === PolicyType.LateResponsePenaltyPolicy
      ? '(' + $tc('gradeAdjustments.late') + ')'
      : '';

  const text = `${prepender}${value}${appender} ${reason}`;

  return {
    id: adjustment.id,
    text,
  };
}
