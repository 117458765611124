import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {makeDependency} from '@/container';
import {AdjustmentDirection, GradeAdjustmentType} from '@/grades/types/RawGradeAdjustment';
import {mode} from '@/common/utils/math';

type GradeAdjustmentServiceType = {
  sum(
    adjustments: GradeAdjustment[],
    taskWeight: number,
    adjustmentType?: GradeAdjustmentType,
    clip?: true
  ): number;
  highestPossibleGrade(
    adjustments: GradeAdjustment[],
    taskWeight: number,
    type?: GradeAdjustmentType
  ): number;
  finalAdjustmentDirection(
    adjustments: GradeAdjustment[],
    taskWeight: number
  ): AdjustmentDirection | null;
  isEmpty(adjustments: GradeAdjustment[]): boolean;
  allRelative(adjustments: GradeAdjustment[]): boolean | null;
  allAbsolute(adjustments: GradeAdjustment[]): boolean | null;
  isMixed(adjustments: GradeAdjustment[]): boolean | null;
  typeMode(adjustments: GradeAdjustment[]): null | GradeAdjustmentType;
};

export const GradeAdjustmentService = makeDependency(makeGradeAdjustmentService);

export function makeGradeAdjustmentService(): GradeAdjustmentServiceType {
  function sum(
    adjustments: GradeAdjustment[],
    taskWeight: number,
    adjustmentType: GradeAdjustmentType = GradeAdjustmentType.Relative,
    clip: boolean = true
  ) {
    if (isEmpty(adjustments)) {
      return 0;
    }
    let sum = adjustments
      .map((ga) => (ga.isAbsolute() ? ga.value : convertToAbsolute(ga.value, taskWeight)))
      .reduce((prev, curr) => prev + curr);

    if (adjustmentType === GradeAdjustmentType.Relative) {
      sum = convertToRelative(sum, taskWeight);
    }

    if (clip) {
      sum = Math.max(sum, adjustmentType === GradeAdjustmentType.Relative ? -100 : -taskWeight);
    }

    return sum;
  }

  function isEmpty(adjustments: GradeAdjustment[]) {
    return adjustments.length === 0;
  }

  function allRelative(adjustments: GradeAdjustment[]) {
    return isEmpty(adjustments) ? null : adjustments.every((ga) => ga.isRelative());
  }

  function allAbsolute(adjustments: GradeAdjustment[]) {
    return isEmpty(adjustments) ? null : adjustments.every((ga) => ga.isAbsolute());
  }

  function isMixed(adjustments: GradeAdjustment[]) {
    return isEmpty(adjustments) ? null : !allRelative(adjustments) && !allAbsolute(adjustments);
  }

  function typeMode(adjustments: GradeAdjustment[]) {
    return isEmpty(adjustments)
      ? null
      : (mode(
          adjustments.map((adjustment) => adjustment.adjustmentType)
        )[0] as GradeAdjustmentType);
  }

  function convertToAbsolute(relative: number, taskWeight: number) {
    return Math.fround(taskWeight * (relative / 100));
  }

  function convertToRelative(absolute: number, taskWeight: number) {
    if (taskWeight === 0) {
      return 0;
    }
    return Math.fround((absolute / taskWeight) * 100);
  }

  function finalAdjustmentDirection(adjustments: GradeAdjustment[], taskWeight: number) {
    const _sum = sum(adjustments, taskWeight, GradeAdjustmentType.Absolute);
    if (_sum === 0) {
      return null;
    }
    return _sum > 0 ? AdjustmentDirection.Bonus : AdjustmentDirection.Penalty;
  }

  function highestPossibleGrade(
    adjustments: GradeAdjustment[],
    taskWeight: number,
    type: GradeAdjustmentType = GradeAdjustmentType.Relative
  ) {
    const _sum = sum(adjustments, taskWeight, GradeAdjustmentType.Absolute, true);
    const grade = taskWeight + _sum;
    return type === GradeAdjustmentType.Relative ? convertToRelative(grade, taskWeight) : grade;
  }

  return {
    isEmpty,
    allRelative,
    allAbsolute,
    isMixed,
    sum,
    finalAdjustmentDirection,
    highestPossibleGrade,
    typeMode,
  };
}
