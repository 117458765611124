export enum ErrorCode {
  INVALID_INVITATION_TOKEN = 100,
  EXPIRED_INVITATION_TOKEN = 101,
  INVALID_CHEMICAL_EQUATION = 102,
  INVALID_REPORT_TYPE = 103,
  GENERIC_NUMPY_EXCEPTION = 104,
  ACCESS_CODE_ALREADY_LINKED = 105,
  ACCESS_CODE_NOT_FOR_COURSE = 106,
  ACCESS_CODE_INVALID = 107,
  PAST_DUE_SUBMISSION_ATTEMPTED = 118,
}
