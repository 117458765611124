var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{staticClass:"text-h5",attrs:{"cols":"12","align-self":"center"}},[_vm._v(_vm._s(_vm.$t('fileUploads.header'))+" ("+_vm._s(_vm.attachments.length)+" uploads)")])],1)],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.attachments,"footer-props":_vm.footerProps,"sort-by":"createdAt","sort-desc":true},scopedSlots:_vm._u([{key:"item.createdAtFormatted",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.createdAt).format('ddd, MMM DD [at] h:mm a')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary attachment-action-button",attrs:{"fab":"","x-small":"","data-test":"upload-preview","data-test-id":item.id},on:{"click":function($event){return _vm.downloadFileUpload(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$i('common.preview')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fileUploads.preview')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary attachment-action-button",attrs:{"fab":"","primary":"","x-small":"","data-test":"upload-download","data-test-id":item.id},on:{"click":function($event){return _vm.downloadFileUpload(item, false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$i('common.download')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fileUploads.download')))])]),(item.ownerId === _vm.$auth.user.id)?_c('s-confirmation-dialog',{attrs:{"title":_vm.$t('fileUploads.deleteConfirmationTitle'),"content":_vm.$t('fileUploads.deleteConfirmationContent', {name: item.filename})},on:_vm._d({},[_vm.CONFIRM_DIALOG_CONFIRM,function($event){return _vm.deleteAttachment(item)}]),scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogAttrs = ref.attrs;
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipAttrs = ref.attrs;
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary",attrs:{"fab":"","primary":"","x-small":"","data-test":"upload-delete","data-test-id":item.id}},'v-btn',Object.assign({}, tooltipAttrs, dialogAttrs),false),Object.assign({}, tooltipOn, dialogOn)),[_c('v-icon',[_vm._v(_vm._s(_vm.$i('common.trash')))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fileUploads.delete')))])])]}}],null,true)}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }