import {inject, makeDependency} from '@/container';
import TaskResponse from '@/tasks/models/TaskResponse';
import {ResponseDotInfo} from '@/tasks/types/ResponseDotInfo';
import {SetupContext} from '@vue/composition-api';
import Grade from '@/grades/models/Grade';
import {DependencyType} from '@/container/types/DependencyType';
import {ThemeService} from '@/theme/services';
import {GradeStatus} from '@/assignments/types/GradeStatus';
import {GetGradeStatus} from '@/assignments/utils/getGradeStatus';
import {IconService} from '@/icons/services/IconService';

export const UseResponseDots = makeDependency((emit: SetupContext['emit']) => {
  const theme = inject(ThemeService);
  const $i = inject(IconService.injectable);
  const getGradeStatus = inject(GetGradeStatus);
  return makeResponseDots(emit, {themeService: theme, $i, getGradeStatus});
});

export function makeResponseDots(
  emit: SetupContext['emit'],
  {
    themeService,
    $i,
    getGradeStatus,
  }: {
    themeService: DependencyType<typeof ThemeService>;
    $i: IconService;
    getGradeStatus: DependencyType<typeof GetGradeStatus>;
  }
) {
  function getResponseIcon(
    grade: Grade | null,
    response: TaskResponse | null,
    checkGraded: boolean = true
  ) {
    if (response && response.isDraft) {
      return $i.resolve('tasks.draft');
    }
    if (grade && response) {
      const isEffective = grade.isEffective && checkGraded;
      if (response.attachments && response.attachments.length > 0) {
        if (isEffective) {
          return $i.resolve('tasks.hasAttachmentsGraded');
        } else {
          return $i.resolve('tasks.hasAttachmentsNormal');
        }
      }

      // FIXME: Doesn't capture bonuses yet
      if (grade.hasAdjustments()) {
        if (isEffective) {
          return $i.resolve('tasks.lateGraded');
        } else {
          return $i.resolve('tasks.lateNormal');
        }
      } else {
        if (isEffective) {
          return $i.resolve('tasks.graded');
        } else {
          return $i.resolve('tasks.normal');
        }
      }
    } else {
      return $i.resolve('tasks.normal');
    }
  }

  function changeSelectedResponse(newIndex: number): void {
    emit('change-selected-response', newIndex);
  }

  function getIconColorAndMessage(response: TaskResponse | null, grade: Grade | null) {
    let color: ResponseDotInfo['color'];
    let message: ResponseDotInfo['message'];

    if (!response && !grade) {
      color = themeService.getTheme().alternateIncomplete;
      message = 'responseStatusMessages.notCompleted';
    } else if (grade) {
      if (grade.isOriginalCorrect()) {
        color = themeService.getTheme().correct;
        message = 'responseStatusMessages.correct';
      } else if (grade.isOriginalPartiallyCorrect()) {
        color = themeService.getTheme().partiallyCorrect;
        message = 'responseStatusMessages.partiallyCorrect';
      } else {
        color = themeService.getTheme().incorrect;
        message = 'responseStatusMessages.incorrect';
      }
    } else if (response && response.isDraft) {
      message = 'responseStatusMessages.draft';
      color = themeService.getTheme().draft;
    } else {
      message = 'responseStatusMessages.responded';
      color = themeService.getTheme().ungraded;
    }
    return {color, message};
  }

  function getResponseInformation(
    response: TaskResponse | null,
    grade: Grade | null = null,
    checkGraded: boolean = true
  ): ResponseDotInfo {
    if (response) {
      grade = grade ?? response.grade;
    }
    return {
      id: response?.id ?? 0,
      status: response ? getGradeStatus(grade) : GradeStatus.Incomplete,
      icon: getResponseIcon(grade, response, checkGraded),
      ...getIconColorAndMessage(response, grade),
      isEffective: !!grade?.isEffective && checkGraded,
      isAdjusted: grade?.hasAdjustments() ?? false,
    };
  }

  return {
    changeSelectedResponse,
    getResponseInformation,
  };
}
