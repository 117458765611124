<template>
  <div>
    <v-menu top offset-y :disabled="!hasButtons">
      <template v-slot:activator="{on: menu, attrs}">
        <v-tooltip top>
          <template v-slot:activator="{on: tooltip}">
            <div v-bind="attrs" v-on="{...tooltip, ...menu}">
              <v-btn
                fab
                small
                color="secondary"
                rounded
                height="30px"
                width="30px"
                class="mx-0"
                :disabled="!hasButtons"
              >
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </div>
          </template>
          {{ hasButtons ? $t('taskButtonsTooltip') : $t('noTaskButtonsTooltip') }}
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item>
          <v-btn
            v-if="showReloadButton"
            target="_blank"
            rel="noopener"
            :class="{'is-active-reload': false}"
            icon
            color="secondary"
            @click="reloadTask"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>

          <v-btn
            v-if="showTextbookButton"
            target="_blank"
            rel="noopener"
            :href="task.textbookLink"
            color="secondary"
            icon
          >
            <v-icon>mdi-book-open-variant</v-icon>
          </v-btn>

          <v-btn v-if="showVideoButton" color="secondary" icon @click="showVideo">
            <v-icon>mdi-video-box</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Task from '@/tasks/models/Task';

export default {
  name: 'TaskButtons',
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  computed: {
    showReloadButton() {
      return false && this.task.isRandomizable();
    },
    showTextbookButton() {
      return this.task.hasTextbookLink();
    },
    showVideoButton() {
      return this.task.hasVideo() && this.task.taskType !== 'WatchVideoTask';
    },
    hasButtons() {
      const {showReloadButton, showTextbookButton, showVideoButton} = this;
      return showReloadButton || showTextbookButton || showVideoButton;
    },
  },
  methods: {
    showVideo() {
      this.$emit('show-video');
    },
    reloadTask() {
      this.$emit('reload-task');
    },
  },
};
</script>
