
















































































































import {defineComponent, PropType, toRef} from '@vue/composition-api';
import FileUpload from '@/tasks/models/FileUpload';
import {inject} from '@/container';
import {UseAttachments} from '@/uploads/composables/UseAttachments';
import {
  default as FileSelectDialog,
  events as FileSelectEvents,
} from '@/uploads/components/FileSelectDialog.vue';
import {
  default as SConfirmationDialog,
  events as ConfirmationDialogEvents,
} from '@/common/components/SConfirmationDialog.vue';
import {FileUploadLinkedEntity} from '@/tasks/types/FileUploadLinkedEntity';

export const events = {
  ATTACHMENT_SUBMIT_EVENT: 'attachment-submit-event',
  ATTACHMENT_DELETED_EVENT: 'attachment-deleted-event',
};

export default defineComponent({
  components: {SConfirmationDialog, FileSelectDialog},
  emits: Object.values(events),
  props: {
    attachments: {
      type: Array as PropType<FileUpload[]>,
    },
    allowNewUploads: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    entityType: {
      type: String as PropType<FileUploadLinkedEntity>,
      required: true,
    },
    entityId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props, {emit}) {
    const {
      headers,
      footerProps,
      fileSelectDialog,
      newFileUpload,
      downloadFileUpload,
      uploadFileSelected,
      uploadNewFileAttachment: uploadAttachment,
      deleteFileUpload,
    } = inject(UseAttachments, toRef(props, 'entityType'), toRef(props, 'entityId'));

    function uploadNewFileAttachment() {
      uploadAttachment().then((response) => {
        emit(events.ATTACHMENT_SUBMIT_EVENT, response.FileUpload);
        return response;
      });
    }

    function deleteAttachment(fileUpload: FileUpload) {
      deleteFileUpload(fileUpload).then((response) => {
        emit(events.ATTACHMENT_DELETED_EVENT, response.data.data.id);
        return response;
      });
    }

    return {
      headers,
      footerProps,
      fileSelectDialog,
      newFileUpload,
      downloadFileUpload,
      ...FileSelectEvents,
      ...ConfirmationDialogEvents,
      uploadFileSelected,
      uploadNewFileAttachment,
      deleteAttachment,
    };
  },
});
