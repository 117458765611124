import {makeGlobalSingleton} from '@/container';
import Grade from '@/grades/models/Grade';
import TaskAssignment from '@/tasks/models/TaskAssignment';
import User from '@/users/models/User';

export const TaskGradeService = makeGlobalSingleton(() => makeTaskGradeService());

export const makeTaskGradeService = () => {
  function submitManualGrade(
    taskAssignment: TaskAssignment,
    user: User,
    numerator: number,
    denominator: number,
    overrideReason: null | string = null,
    responseId: number | null
  ) {
    return Grade.api.submitManualGrade({
      task: taskAssignment.taskId,
      assignment: taskAssignment.assignmentId,
      user: user.id,
      numerator,
      denominator,
      overrideReason,
      responseId: responseId,
    });
  }

  return {
    submitManualGrade,
  };
};
