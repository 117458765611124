import TaskResponseTaskFeedback, {DEFAULT_PART_NAME} from '@/tasks/models/TaskResponseTaskFeedback';
import {computed, ComputedRef, Ref} from '@vue/composition-api';
import {getFeedbackTextByPart} from '@/tasks/utils/feedback';
import Grade from '@/grades/models/Grade';
import TaskResponse from '@/tasks/models/TaskResponse';
import {useTranslate} from '@/locales/composables/i18n';
import {inject} from '@/container';
import {GenericTaskFeedbackService} from '@/tasks/services/GenericTaskFeedbackService';

export function useOrderedFeedbackParts(
  feedbackByPart: Ref<Record<string, TaskResponseTaskFeedback[]> | null>,
  grade: Ref<Grade | null>,
  response: Ref<TaskResponse | null>
) {
  const $t = useTranslate();
  const feedbackService = inject(GenericTaskFeedbackService);
  const genericFeedbackText = computed(() => {
    if (!grade.value) {
      let feedbackResponse = feedbackService.getRandomIndeterminateFeedback();
      if (response.value?.gradingStatus) {
        feedbackResponse +=
          ' ' + feedbackService.getGradingStatusMessage(response.value?.gradingStatus);
      }
      return feedbackResponse;
    }

    if (grade.value?.isManuallyEntered) {
      const reason = grade.value?.overrideReason
        ? `${$t('manualGrades.instructorFeedbackLabel')}: ${grade.value?.overrideReason}`
        : $t('manualGrades.noInstructorFeedbackText');
      return reason;
    }
    return grade.value?.isOriginalCorrect()
      ? feedbackService.getRandomCorrectFeedback()
      : feedbackService.getRandomIncorrectFeedback();
  });
  const feedbackTextByPart = computed(() => {
    const texts = getFeedbackTextByPart(feedbackByPart.value);
    if (grade.value?.isManuallyEntered || !Object.keys(texts).length) {
      return {
        default: [genericFeedbackText.value],
      };
    }

    return texts;
  });

  const feedbackPartNames = computed(() => {
    let parts = Object.keys(feedbackTextByPart.value).sort();
    if (!parts.length) {
      return [DEFAULT_PART_NAME];
    }

    // Puts general at the top of the list
    const defaultIndex = parts.indexOf(DEFAULT_PART_NAME);

    if (defaultIndex !== -1) {
      parts.splice(defaultIndex, 1);
      parts = [DEFAULT_PART_NAME, ...parts];
    }

    return parts;
  });

  const isOnlyDefaultFeedback = computed(() => {
    return feedbackPartNames.value.length === 1 && feedbackPartNames.value[0] === DEFAULT_PART_NAME;
  });

  const isSingleFeedback = computed(() => {
    return isOnlyDefaultFeedback.value && feedbackTextByPart.value[DEFAULT_PART_NAME].length === 1;
  });
  return {
    feedbackTextByPart,
    feedbackPartNames,
    isOnlyDefaultFeedback,
    isSingleFeedback,
    genericFeedbackText,
  };
}
