import {Fraction} from '@/grades/types/Fraction';

// https://stackoverflow.com/a/27865285
export function precision(a: number): number {
  if (!isFinite(a)) {
    return 0;
  }
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

export function convertFractionComponentsToWholeNumbersAndReduce(fraction: Fraction) {
  const numeratorPrecision = precision(fraction.numerator);
  const denominatorPrecision = precision(fraction.denominator);

  const maxPrecision = Math.max(numeratorPrecision, denominatorPrecision);
  const scalingFactor = Math.pow(10, maxPrecision);
  return reduce({
    numerator: Math.round(fraction.numerator * scalingFactor),
    denominator: Math.round(fraction.denominator * scalingFactor),
  });
}

// https://stackoverflow.com/a/4652513
export function reduce(fraction: Fraction): Fraction {
  const gcd = greatestCommonDenominator(fraction.numerator, fraction.denominator);
  return {
    numerator: Math.round(fraction.numerator / gcd),
    denominator: Math.round(fraction.denominator / gcd),
  };
}

export function greatestCommonDenominator(a: number, b: number): number {
  return b ? greatestCommonDenominator(b, a % b) : a;
}

export function mode(arr: (string | number)[]) {
  const hash: Record<string | number, number> = {};
  [...arr].map((a) => {
    hash[a] === undefined ? (hash[a] = 0) : (hash[a] += 1);
  });
  const max = Math.max(...Object.values(hash));
  return Object.keys(hash).filter((key) => hash[key] === max);
}
