








































import {computed, defineComponent, PropType, ref} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';

export const events = {
  FILE_SELECT_SUBMIT_EVENT: 'file-select-submit',
};

export default defineComponent({
  components: {},
  emits: Object.values(events),
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
    },
    files: {
      type: Array as PropType<Array<File>>,
    },
    submitText: {
      type: String as PropType<String>,
      required: false,
    },
  },
  setup(props, {emit}) {
    const internalValue = useVModel(props, 'modelValue');
    const selectedFiles = useVModel(props, 'files');
    const uploadFileSelected = computed((): boolean => {
      return !!selectedFiles.value;
    });

    function submit() {
      internalValue.value = false;
      emit(events.FILE_SELECT_SUBMIT_EVENT);
    }

    function deleteChip(index: number) {
      selectedFiles.value?.splice(index, 1);
    }

    return {
      internalValue,
      selectedFiles,
      uploadFileSelected,
      deleteChip,
      submit,
    };
  },
});
