
































import {computed, defineComponent, PropType} from '@vue/composition-api';
import Grade from '@/grades/models/Grade';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {UseBuildAdjustmentInfo} from '@/tasks/composables/UseBuildAdjustmentInfo';
import {inject} from '@/container';

export default defineComponent({
  name: 'TaskGrade',
  props: {
    grade: {
      type: Grade as PropType<Grade>,
      required: true,
    },
    weight: {
      type: Number as PropType<number>,
      default: 1,
    },
  },
  setup(props) {
    const buildAdjustmentInfo = inject(UseBuildAdjustmentInfo);

    const adjustmentInfo = computed(() => adjustments.value.map(buildAdjustmentInfo));

    const taskGradeText = computed(() => {
      if (!props.grade || !props.weight) {
        return null;
      }

      let numerator = props.grade.decimalGrade * props.weight;
      let stringNumerator: string = '0';
      if (numerator !== 0) {
        stringNumerator = numerator.toFixed(2);
      }

      return `${stringNumerator}/${props.weight.toFixed(2)}`;
    });

    const integerGrade = computed(() => props.grade.integerGrade);

    const adjustments = computed(() =>
      // FIXME: Filtering to avoid weird vuex-orm bug
      GradeAdjustment.fullQuery({})
        .where('gradeId', props.grade.id)
        .all()
        .filter((ga) => ga.$id !== 'undefined')
    );
    const hasAdjustments = computed(() => adjustments.value.length > 0);

    return {
      taskGradeText,
      integerGrade,
      adjustments,
      hasAdjustments,
      adjustmentInfo,
    };
  },
});
