















import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'PeriodicTable',
  data: function () {
    return {
      elements: [
        {
          type: 'hydrogen',
          name: 'Hydrogen',
          atomicWeight: '1.008',
          symbol: 'H',
        },
        {
          type: 'helium',
          name: 'Helium',
          atomicWeight: '4.0026',
          symbol: 'He',
        },
        {
          type: 'alkali',
          name: 'Lithium',
          atomicWeight: '6.94',
          symbol: 'Li',
        },
        {
          type: 'alkaline',
          name: 'Beryllium',
          atomicWeight: '9.0122',
          symbol: 'Be',
        },
        {
          type: 'metalloid',
          name: 'Boron',
          atomicWeight: '10.81',
          symbol: 'B',
        },
        {
          type: 'polyatomic-non-metal',
          name: 'Carbon',
          atomicWeight: '12.011',
          symbol: 'C',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Nitrogen',
          atomicWeight: '14.007',
          symbol: 'N',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Oxygen',
          atomicWeight: '15.999',
          symbol: 'O',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Fluorine',
          atomicWeight: '18.9984',
          symbol: 'F',
        },
        {
          type: 'noble-gas',
          name: 'Neon',
          atomicWeight: '20.1797',
          symbol: 'Ne',
        },
        {
          type: 'alkali',
          name: 'Sodium',
          atomicWeight: '22.9898',
          symbol: 'Na',
        },
        {
          type: 'alkaline',
          name: 'Magnesium',
          atomicWeight: '24.305',
          symbol: 'Mg',
        },
        {
          type: 'aluminum',
          name: 'Aluminum',
          atomicWeight: '26.9815',
          symbol: 'Al',
        },
        {
          type: 'metalloid',
          name: 'Silicon',
          atomicWeight: '28.085',
          symbol: 'Si',
        },
        {
          type: 'polyatomic-non-metal',
          name: 'Phosphorus',
          atomicWeight: '30.9738',
          symbol: 'P',
        },
        {
          type: 'polyatomic-non-metal',
          name: 'Sulfur',
          atomicWeight: '32.06',
          symbol: 'S',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Chlorine',
          atomicWeight: '35.45',
          symbol: 'Cl',
        },
        {
          type: 'noble-gas',
          name: 'Argon',
          atomicWeight: '39.95',
          symbol: 'Ar',
        },
        {
          type: 'alkali',
          name: 'Potassium',
          atomicWeight: '39.0983',
          symbol: 'K',
        },
        {
          type: 'alkaline',
          name: 'Calcium',
          atomicWeight: '40.078',
          symbol: 'Ca',
        },
        {
          type: 'scandium',
          name: 'Scandium',
          atomicWeight: '44.9559',
          symbol: 'Sc',
        },
        {
          type: 'titanium',
          name: 'Titanium',
          atomicWeight: '47.867',
          symbol: 'Ti',
        },
        {
          type: 'vanadium',
          name: 'Vanadium',
          atomicWeight: '50.9415',
          symbol: 'V',
        },
        {
          type: 'chromium',
          name: 'Chromium',
          atomicWeight: '51.996',
          symbol: 'Cr',
        },
        {
          type: 'manganese',
          name: 'Manganese',
          atomicWeight: '54.938',
          symbol: 'Mn',
        },
        {
          type: 'iron',
          name: 'Iron',
          atomicWeight: '55.845',
          symbol: 'Fe',
        },
        {
          type: 'cobalt',
          name: 'Cobalt',
          atomicWeight: '58.9332',
          symbol: 'Co',
        },
        {
          type: 'nickel',
          name: 'Nickel',
          atomicWeight: '58.6934',
          symbol: 'Ni',
        },
        {
          type: 'copper',
          name: 'Copper',
          atomicWeight: '63.546',
          symbol: 'Cu',
        },
        {
          type: 'zinc',
          name: 'Zinc',
          atomicWeight: '65.38',
          symbol: 'Zn',
        },
        {
          type: 'gallium',
          name: 'Gallium',
          atomicWeight: '69.723',
          symbol: 'Ga',
        },
        {
          type: 'metalloid',
          name: 'Germanium',
          atomicWeight: '72.630',
          symbol: 'Ge',
        },
        {
          type: 'metalloid',
          name: 'Arsenic',
          atomicWeight: '74.9216',
          symbol: 'As',
        },
        {
          type: 'polyatomic-non-metal',
          name: 'Selenium',
          atomicWeight: '78.971',
          symbol: 'Se',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Bromine',
          atomicWeight: '79.904',
          symbol: 'Br',
        },
        {
          type: 'noble-gas',
          name: 'Krypton',
          atomicWeight: '83.798',
          symbol: 'Kr',
        },
        {
          type: 'alkali',
          name: 'Rubidium',
          atomicWeight: '85.4678',
          symbol: 'Rb',
        },
        {
          type: 'alkaline',
          name: 'Strontium',
          atomicWeight: '87.62',
          symbol: 'Sr',
        },
        {
          type: 'yttrium',
          name: 'Yttrium',
          atomicWeight: '88.9058',
          symbol: 'Y',
        },
        {
          type: 'zirconium',
          name: 'Zirconium',
          atomicWeight: '91.224',
          symbol: 'Zr',
        },
        {
          type: 'niobium',
          name: 'Niobium',
          atomicWeight: '92.9064',
          symbol: 'Nb',
        },
        {
          type: 'molybdenum',
          name: 'Molybdenum',
          atomicWeight: '95.95',
          symbol: 'Mo',
        },
        {
          type: 'technetium',
          name: 'Technetium',
          atomicWeight: '98',
          symbol: 'Tc',
        },
        {
          type: 'ruthenium',
          name: 'Ruthenium',
          atomicWeight: '101.07',
          symbol: 'Ru',
        },
        {
          type: 'rhodium',
          name: 'Rhodium',
          atomicWeight: '102.9055',
          symbol: 'Rh',
        },
        {
          type: 'palladium',
          name: 'Palladium',
          atomicWeight: '106.42',
          symbol: 'Pd',
        },
        {
          type: 'silver',
          name: 'Silver',
          atomicWeight: '107.8682',
          symbol: 'Ag',
        },
        {
          type: 'cadmium',
          name: 'Cadmium',
          atomicWeight: '112.414',
          symbol: 'Cd',
        },
        {
          type: 'indium',
          name: 'Indium',
          atomicWeight: '114.818',
          symbol: 'In',
        },
        {
          type: 'tin',
          name: 'Tin',
          atomicWeight: '118.71',
          symbol: 'Sn',
        },
        {
          type: 'metalloid',
          name: 'Antimony',
          atomicWeight: '121.760',
          symbol: 'Sb',
        },
        {
          type: 'metalloid',
          name: 'Tellurium',
          atomicWeight: '127.60',
          symbol: 'Te',
        },
        {
          type: 'diatomic-nonmetal',
          name: 'Iodine',
          atomicWeight: '126.9045',
          symbol: 'I',
        },
        {
          type: 'noble-gas',
          name: 'Xenon',
          atomicWeight: '131.293',
          symbol: 'Xe',
        },
        {
          type: 'alkali',
          name: 'Caesium',
          atomicWeight: '131.9055',
          symbol: 'Cs',
        },
        {
          type: 'alkaline',
          name: 'Barium',
          atomicWeight: '137.327',
          symbol: 'Ba',
        },
        {
          type: 'lanthanide',
          name: 'Lanthanum',
          atomicWeight: '137.327',
          symbol: 'La',
        },
        {
          type: 'lanthanide',
          name: 'Cerium',
          atomicWeight: '140.116',
          symbol: 'Ce',
        },
        {
          type: 'lanthanide',
          name: 'Praseodymium',
          atomicWeight: '140.9077',
          symbol: 'Pr',
        },
        {
          type: 'lanthanide',
          name: 'Neodymium',
          atomicWeight: '144.242',
          symbol: 'Nd',
        },
        {
          type: 'lanthanide',
          name: 'Promethium',
          atomicWeight: '145',
          symbol: 'Pm',
        },
        {
          type: 'lanthanide',
          name: 'Samarium',
          atomicWeight: '150.36',
          symbol: 'Sm',
        },
        {
          type: 'lanthanide',
          name: 'Europium',
          atomicWeight: '151.964',
          symbol: 'Eu',
        },
        {
          type: 'lanthanide',
          name: 'Gadolinium',
          atomicWeight: '157.25',
          symbol: 'Gd',
        },
        {
          type: 'lanthanide',
          name: 'Terbium',
          atomicWeight: '158.9254',
          symbol: 'Tb',
        },
        {
          type: 'lanthanide',
          name: 'Dysprosium',
          atomicWeight: '162.50',
          symbol: 'Dy',
        },
        {
          type: 'lanthanide',
          name: 'Holmium',
          atomicWeight: '164.9303',
          symbol: 'Ho',
        },
        {
          type: 'lanthanide',
          name: 'Erbium',
          atomicWeight: '167.259',
          symbol: 'Er',
        },
        {
          type: 'lanthanide',
          name: 'Thulium',
          atomicWeight: '168.9342',
          symbol: 'Tm',
        },
        {
          type: 'lanthanide',
          name: 'Ytterbium',
          atomicWeight: '173.045',
          symbol: 'Yb',
        },
        {
          type: 'lanthanide',
          name: 'Lutetium',
          atomicWeight: '174.9668',
          symbol: 'Lu',
        },
        {
          type: 'hafnium',
          name: 'Hafnium',
          atomicWeight: '178.486',
          symbol: 'Hf',
        },
        {
          type: 'tantalum',
          name: 'Tantalum',
          atomicWeight: '180.9479',
          symbol: 'Ta',
        },
        {
          type: 'tungsten',
          name: 'Tungsten',
          atomicWeight: '183.84',
          symbol: 'W',
        },
        {
          type: 'rhenium',
          name: 'Rhenium',
          atomicWeight: '186.207',
          symbol: 'Re',
        },
        {
          type: 'osmium',
          name: 'Osmium',
          atomicWeight: '190.23',
          symbol: 'Os',
        },
        {
          type: 'iridium',
          name: 'Iridium',
          atomicWeight: '192.217',
          symbol: 'Ir',
        },
        {
          type: 'platinum',
          name: 'Platinum',
          atomicWeight: '195.084',
          symbol: 'Pt',
        },
        {
          type: 'gold',
          name: 'Gold',
          atomicWeight: '196.9666',
          symbol: 'Au',
        },
        {
          type: 'mercury',
          name: 'Mercury',
          atomicWeight: '200.592',
          symbol: 'Hg',
        },
        {
          type: 'thallium',
          name: 'Thallium',
          atomicWeight: '204.38',
          symbol: 'Tl',
        },
        {
          type: 'lead',
          name: 'Lead',
          atomicWeight: '207.2',
          symbol: 'Pb',
        },
        {
          type: 'bismuth',
          name: 'Bismuth',
          atomicWeight: '208.9804',
          symbol: 'Bi',
        },
        {
          type: 'polonium',
          name: 'Polonium',
          atomicWeight: '209',
          symbol: 'Po',
        },
        {
          type: 'metalloid',
          name: 'Astatine',
          atomicWeight: '210',
          symbol: 'At',
        },
        {
          type: 'noble-gas',
          name: 'Radon',
          atomicWeight: '222',
          symbol: 'Rn',
        },
        {
          type: 'alkali',
          name: 'Francium',
          atomicWeight: '223',
          symbol: 'Fr',
        },
        {
          type: 'alkaline',
          name: 'Radium',
          atomicWeight: '226',
          symbol: 'Ra',
        },
        {
          type: 'actinide',
          name: 'Actinium',
          atomicWeight: '227',
          symbol: 'Ac',
        },
        {
          type: 'actinide',
          name: 'Thorium',
          atomicWeight: '232.0377',
          symbol: 'Th',
        },
        {
          type: 'actinide',
          name: 'Protactinium',
          atomicWeight: '231.0359',
          symbol: 'Pa',
        },
        {
          type: 'actinide',
          name: 'Uranium',
          atomicWeight: '238.0289',
          symbol: 'U',
        },
        {
          type: 'actinide',
          name: 'Neptunium',
          atomicWeight: '237',
          symbol: 'Np',
        },
        {
          type: 'actinide',
          name: 'Plutonium',
          atomicWeight: '244',
          symbol: 'Pu',
        },
        {
          type: 'actinide',
          name: 'Americium',
          atomicWeight: '243',
          symbol: 'Am',
        },
        {
          type: 'actinide',
          name: 'Curium',
          atomicWeight: '247',
          symbol: 'Cm',
        },
        {
          type: 'actinide',
          name: 'Berkelium',
          atomicWeight: '247',
          symbol: 'Bk',
        },
        {
          type: 'actinide',
          name: 'Californium',
          atomicWeight: '251',
          symbol: 'Cf',
        },
        {
          type: 'actinide',
          name: 'Einsteinium',
          atomicWeight: '252',
          symbol: 'Es',
        },
        {
          type: 'actinide',
          name: 'Fermium',
          atomicWeight: '257',
          symbol: 'Fm',
        },
        {
          type: 'actinide',
          name: 'Mendelevium',
          atomicWeight: '258',
          symbol: 'Md',
        },
        {
          type: 'actinide',
          name: 'Nobelium',
          atomicWeight: '259',
          symbol: 'No',
        },
        {
          type: 'actinide',
          name: 'Lawrencium',
          atomicWeight: '266',
          symbol: 'Lr',
        },
        {
          type: 'rutherfordium',
          name: 'Rutherfordium',
          atomicWeight: '267',
          symbol: 'Rf',
        },
        {
          type: 'dubnium',
          name: 'Dubnium',
          atomicWeight: '268',
          symbol: 'Db',
        },
        {
          type: 'seaborgium',
          name: 'Seaborgium',
          atomicWeight: '269',
          symbol: 'Up',
        },
        {
          type: 'bohrium',
          name: 'Bohrium',
          atomicWeight: '270',
          symbol: 'Bh',
        },
        {
          type: 'hassium',
          name: 'Hassium',
          atomicWeight: '277',
          symbol: 'Hs',
        },
        {
          type: 'unknown',
          name: 'Meitnerium',
          atomicWeight: '278',
          symbol: 'Mt',
        },
        {
          type: 'unknown',
          name: 'Darmstadtium',
          atomicWeight: '281',
          symbol: 'Ds',
        },
        {
          type: 'unknown',
          name: 'Roentgenium',
          atomicWeight: '282',
          symbol: 'Rg',
        },
        {
          type: 'unknown',
          name: 'Copernicium',
          atomicWeight: '285',
          symbol: 'Cn',
        },
        {
          type: 'unknown',
          name: 'Nihonium',
          atomicWeight: '286',
          symbol: 'Nh',
        },
        {
          type: 'unknown',
          name: 'Flerovium',
          atomicWeight: '289',
          symbol: 'Fl',
        },
        {
          type: 'unknown',
          name: 'Moscovium',
          atomicWeight: '290',
          symbol: 'Mc',
        },
        {
          type: 'unknown',
          name: 'Livermorium',
          atomicWeight: '293',
          symbol: 'Lv',
        },
        {
          type: 'unknown',
          name: 'Tennessine',
          atomicWeight: '294',
          symbol: 'Ts',
        },
        {
          type: 'unknown',
          name: 'Oganesson',
          atomicWeight: '294',
          symbol: 'Og',
        },
      ],
    };
  },
});
