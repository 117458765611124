import BaseGrade from '@/grades/models/BaseGrade';

/**
 * Gets the color class(es) for displaying coloured Grade values
 *
 * @param grade
 */
export function getGradeColorClass(grade: BaseGrade | null | undefined | number): string {
  if (grade) {
    const integerGrade = typeof grade !== 'number' ? grade.integerGrade : grade;
    if (integerGrade <= 25) {
      return 'red--text accent-4';
    } else if (integerGrade > 25 && integerGrade <= 50) {
      return 'deep-orange--text darken-4';
    } else if (integerGrade > 50 && integerGrade <= 75) {
      return 'blue--text darken-4';
    }
    return `green--text darken-3`;
  }

  return 'black--text';
}

/**
 * Gets the color for displaying coloured Grade values, defaults to blue
 *
 * @param grade
 */
export function getGradeColor(grade: BaseGrade | null | undefined | number): string {
  if (grade) {
    const integerGrade = typeof grade !== 'number' ? grade.integerGrade : grade;
    if (integerGrade <= 25) {
      return 'red';
    } else if (integerGrade > 25 && integerGrade <= 50) {
      return 'orange';
    } else if (integerGrade > 50 && integerGrade <= 75) {
      return 'blue';
    }
    return `green`;
  }

  return 'grey';
}
