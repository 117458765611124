import {makeGlobalSingleton} from '@/container';
import {useTranslate} from '@/locales/composables/i18n';

/**
 * Responsible for getting random, generic feedback for tasks
 */
export const GenericTaskFeedbackService = makeGlobalSingleton(() => {
  const $t = useTranslate();

  function getFeedbacksOfType(feedbackType: 'correct' | 'incorrect' | 'indeterminate') {
    return $t(`assignmentFeedback.${feedbackType}`) as unknown as Record<string, string>;
  }

  function pickRandomFeedbackFrom(feedbacks: Record<string, string>): string {
    const length = Object.keys(feedbacks).length;
    const index = Math.floor(Math.random() * length);
    return feedbacks[index];
  }

  function getRandomCorrectFeedback() {
    return pickRandomFeedbackFrom(getFeedbacksOfType('correct'));
  }
  function getRandomIncorrectFeedback() {
    return pickRandomFeedbackFrom(getFeedbacksOfType('incorrect'));
  }
  function getRandomIndeterminateFeedback() {
    return pickRandomFeedbackFrom(getFeedbacksOfType('indeterminate'));
  }
  function getGradingStatusMessage(gradingStatus: string) {
    const statusMessage = $t(`gradingStatusMessages.${gradingStatus}`) as string;
    return statusMessage !== undefined ? statusMessage : '';
  }

  return {
    getRandomCorrectFeedback,
    getRandomIncorrectFeedback,
    getRandomIndeterminateFeedback,
    getGradingStatusMessage,
  };
});
