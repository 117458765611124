import {inject, makeDependency} from '@/container';
import {computed, Ref} from '@vue/composition-api';
import {GradeAdjustment} from '@/grades/models/GradeAdjustment';
import {GradeAdjustmentService} from '@/tasks/services/GradeAdjustmentService';
import {DependencyType} from '@/container/types/DependencyType';
import {useTranslate} from '@/locales/composables/i18n';
import {AdjustmentDirection, GradeAdjustmentType} from '@/grades/types/RawGradeAdjustment';
import {TranslateResult} from 'vue-i18n';

export const UseAdjustmentFeedbackPostScript = makeDependency(
  (adjustments: Ref<GradeAdjustment[]>, taskWeight: Ref<number>) => {
    return makeAdjustmentFeedbackPostScript(adjustments, taskWeight, {
      $t: useTranslate(),
      service: inject(GradeAdjustmentService),
    });
  }
);

export function makeAdjustmentFeedbackPostScript(
  adjustments: Ref<GradeAdjustment[]>,
  taskWeight: Ref<number>,
  {
    service,
    $t,
  }: {
    service: DependencyType<typeof GradeAdjustmentService>;
    $t: (...args: any[]) => TranslateResult;
  }
) {
  const adjustmentType = computed(
    () => service.typeMode(adjustments.value) ?? GradeAdjustmentType.Relative
  );
  const adjustmentsApplied = computed(
    () => taskWeight.value !== 0 && service.sum(adjustments.value, taskWeight.value) !== 0
  );

  const adjustmentReason = computed(() => {
    if (!adjustmentsApplied) {
      return '';
    }

    const adjustment = Math.abs(
      service.sum(adjustments.value, taskWeight.value, adjustmentType.value)
    ).toFixed(0);
    if (
      service.finalAdjustmentDirection(adjustments.value, taskWeight.value) ===
      AdjustmentDirection.Penalty
    ) {
      return (
        $t(`gradeAdjustments.${adjustmentType.value}LatePenaltyReason`, {adjustment}) +
        ' ' +
        $t(`gradeAdjustments.${adjustmentType.value}HighestAchievableGrade`, {
          grade: service
            .highestPossibleGrade(adjustments.value, taskWeight.value, adjustmentType.value)
            .toFixed(0),
        })
      );
    } else if (
      service.finalAdjustmentDirection(adjustments.value, taskWeight.value) ===
      AdjustmentDirection.Bonus
    ) {
      return (
        $t(`gradeAdjustments.${adjustmentType.value}BonusReason`, {adjustment}) +
        ' ' +
        $t(`gradeAdjustments.${adjustmentType.value}HighestAchievableGrade`, {
          grade: service
            .highestPossibleGrade(adjustments.value, taskWeight.value, adjustmentType.value)
            .toFixed(0),
        })
      );
    } else {
      return '';
    }
  });

  return {
    adjustmentsApplied,
    adjustmentReason,
  };
}
